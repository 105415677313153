/*
| Developed by Dirupt
| Filename : user-information.contract.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { UserInformation } from '@/services/api/modules/user-information/user-information.contract'
import { UserProfile } from '@/services/api/modules/user-profile/user-profile.contract'
import { UserQuestion } from '@/services/api/modules/user-question/user-question.contract'
import { StripeSubscriptionStatuses, UserPlanIds } from '@/services/api/modules/user-plan/user-plan.contract'
import { SumsubApplicantStatus } from '@/services/api/modules/sumsub/sumsub.contract'
import { RedeemCode } from '@/services/api/modules/redeem-code/redeem-code.contract'

/*
|--------------------------------------------------------------------------
| Enums
|--------------------------------------------------------------------------
*/
export enum UserRole {
	DEVELOPER = 'DEVELOPER',
	ADMIN = 'ADMIN',
	AMBASSADOR = 'AMBASSADOR',
	PARTNER = 'PARTNER',
	USER = 'USER',
}

/*
|--------------------------------------------------------------------------
| User
|--------------------------------------------------------------------------
*/
export type User = {
	// Fields
	id: string
	email: string
	emailVerified: boolean
	rememberMeToken: string | null
	betaAccess: boolean
	role: UserRole
	preferredLanguage: string
	hidden: boolean
	// Support
	supportAccess: boolean
	// Stripe
	planId: UserPlanIds
	planPeriodStart: string | null
	planPeriodEnd: string | null
	subscriptionStatus: StripeSubscriptionStatuses | null
	subscriptionPausedAt: string | null
	subscriptionCanceledAt: string | null
	// Sumsub
	kycStatus: SumsubApplicantStatus | null
	kycVerified: boolean
	kycVerifiedAt: string | null
	// Timestamps
	createdAt: string
	updatedAt: string
	// Computed
	isEmailVerified: boolean
	isDeveloper: boolean
	isAdmin: boolean
	isAmbassador: boolean
	isPartner: boolean
	isUser: boolean
	isIdle: boolean
	isLocked: boolean
	isBanned: boolean
	isRedeemed: boolean
	isCertified: boolean
	isSubscriptionActive: boolean
	isSubscriptionCanceledNextPeriod: boolean
	isFreeTier: boolean
	// isTrial: boolean
	// isBetaFreePeriod: boolean
	// betaPeriodEnd: string
	memberDuration: string | null
	// Relations
	userInformation: UserInformation
	profiles: Array<UserProfile>
	question: UserQuestion
}

/*
|--------------------------------------------------------------------------
| Transformation - User find one
|--------------------------------------------------------------------------
*/
export type UserFindOne = User & {
	blocked: boolean
	redeemCodeUsed?: RedeemCode
}

/*
|--------------------------------------------------------------------------
| User - Last Registered
|--------------------------------------------------------------------------
*/
export type UserLastRegistered = User

/*
|--------------------------------------------------------------------------
| Followers / Following
|--------------------------------------------------------------------------
*/
export type UserFollowersFollowings = UserInformation & {
	profiles: Array<UserProfile>
	when: Date
}
