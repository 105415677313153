/*
| Developed by Dirupt
| Filename : SenskleAppStructure.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { DefaultSeo } from 'next-seo';
import CssBaseline from '@mui/material/CssBaseline';
import { AnimatePresence, m } from 'framer-motion';
import { PaletteMode } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import merge from 'lodash/merge';
import { enUS, frFR, Localization } from '@mui/material/locale';
import { Router } from 'next/router';
import { Loader } from '@googlemaps/js-api-loader';
import { Settings } from 'luxon';
import { useGetCanonialUrl } from '@/hooks/useGetCanonialUrl';
import { AvailableLanguages } from '@/contracts/enums';
import { getThemePalette } from '@/styles/theme.palette';
import defaultTheme from '@/styles/theme';
import { tabOverrides } from '@/styles/overrides/tab.overrides';
import { buttonOverrides } from '@/styles/overrides/button.overrides';
import { switchOverrides } from '@/styles/overrides/switch.overrides';
import { chipOverrides } from '@/styles/overrides/chip.overrides';
import { radioOverrides } from '@/styles/overrides/radio.overrides';
import { textFieldOverrides } from '@/styles/overrides/textfield.overrides';
import { selectOverrides } from '@/styles/overrides/select.overrides';
import { linkOverrides } from '@/styles/overrides/link.overrides';
import { CookiesService } from '@/services/cookies/cookies.service';
import { YupService } from '@/services/yup/yup.service';
import { Dictionary } from '@/utils';
import { useAppDispatch, useAppSelector } from '@/stores/hooks';
import { selectThemeMode, setThemeMode, selectLanguage, setLanguage } from '@/stores/common/commonSlice';
import { dialogOverrides } from '@/styles/overrides/dialog.overrides';
import { HistoryManagerContext, useHistoryManager } from '@/hooks/useHistory';
import { cardOverrides } from '@/styles/overrides/card.overrides';
import { MotionLazy } from '@/components/animate';
import { fabOverrides } from '@/styles/overrides/fab.overrides';
import { formControlOverrides } from '@/styles/overrides/form-control.overrides';
import { axiosInstance } from '@/services/api/axios-instance';
import { tooltipOverrides } from '@/styles/overrides/tooltip.overrides';
import { SenskleExtendNextPageProps } from '@/components/ssr/SensklePage';
import { SenskleTextSvgAnimated } from '@/components/common/svgs/logo/SenskleTextSvgAnimated';
import { accordionOverrides } from '@/styles/overrides/accordion.overrides';
import { menuOverrides } from '@/styles/overrides/menu.overrides';
import { ToastConfig } from '@/components/structure/ToastConfig';
import { DEFAULT_SEO_PROPS, DefaultSeoPropsExtra } from '@/config/seo.config';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
type SenskleAppStructureProps = {
  children: React.ReactNode;
  router: Router;
  layout?: SenskleExtendNextPageProps['layout'];
};

/*
|--------------------------------------------------------------------------
| Localization array for connected Material UI with Next Translate
|--------------------------------------------------------------------------
*/
const connectedLanguages: Dictionary<Localization, AvailableLanguages> = {
  fr: frFR,
  en: enUS
};

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const SenskleAppStructure: React.FC<SenskleAppStructureProps> = ({
  children,
  router
}) => {
  const historyManager = useHistoryManager();
  const themeMode = useAppSelector(selectThemeMode);
  const language = useAppSelector(selectLanguage);
  const key = React.useId();
  const {
    url
  } = useGetCanonialUrl(router);
  const dispatch = useAppDispatch();

  // Load Google Maps and Places API
  // ----------------------------------------------------------------------------
  React.useEffect(() => {
    const init = async () => {
      if (!window.google || !window.google.maps || !window.google.maps.places) {
        const loader = new Loader({
          apiKey: 'AIzaSyD6NCRqi-wB5QDRK7VmKFFqN8ssPIYhRuM',
          libraries: ['places']
        });
        await loader.importLibrary('places');
        console.info('Google Maps API loaded');
        Settings.defaultLocale = language;
        Settings.defaultZone = 'Europe/Paris';
      }
    };
    try {
      setTimeout(() => void init(), 1000);
    } catch (error) {
      console.error('Unable to load Google Maps API:', error);
    }
  }, [language]);

  // Create default SEO data props
  // ----------------------------------------------------------------------------
  const defaultDataProps = React.useMemo<DefaultSeoPropsExtra>(() => {
    // Prepare data with component data
    const seoProps: DefaultSeoPropsExtra = {
      canonical: url,
      openGraph: {
        url
      }
    };

    // Deep merging
    return merge(DEFAULT_SEO_PROPS, seoProps);
  }, [url]);

  // MUI theme
  // ----------------------------------------------------------------------------
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const colorModeCookie = CookiesService.getCookie('senskle-color-mode');
      if (!colorModeCookie) {
        const date = new Date();
        const expireMs = 100 * 24 * 60 * 60 * 1000; // 100 days
        date.setTime(date.getTime() + expireMs);
        CookiesService.setCookie('senskle-color-mode', themeMode, {
          expires: date
        });
      } else {
        dispatch(setThemeMode(colorModeCookie as PaletteMode));
      }
    }
  }, [dispatch, themeMode]);
  const MUITheme = React.useMemo(() => {
    const locale: AvailableLanguages = language as AvailableLanguages;

    // Get palette from theme mode
    const palette = getThemePalette(themeMode);

    // Create theme with palette
    const themeWithOptions = createTheme({
      ...defaultTheme,
      palette
    });

    // Create theme with overrides
    return createTheme({
      ...themeWithOptions,
      components: {
        ...dialogOverrides(themeWithOptions),
        ...buttonOverrides(themeWithOptions),
        ...switchOverrides(themeWithOptions),
        ...chipOverrides(themeWithOptions),
        ...radioOverrides(themeWithOptions),
        ...textFieldOverrides(themeWithOptions),
        ...selectOverrides(themeWithOptions),
        ...linkOverrides(),
        ...cardOverrides(themeWithOptions),
        ...fabOverrides(themeWithOptions),
        ...formControlOverrides(themeWithOptions),
        ...tooltipOverrides(themeWithOptions),
        ...accordionOverrides(themeWithOptions),
        ...menuOverrides(themeWithOptions),
        ...tabOverrides(themeWithOptions)
      }
    }, connectedLanguages?.[locale] ?? frFR);
  }, [language, themeMode]);

  // API language change
  // ----------------------------------------------------------------------------
  React.useEffect(() => {
    const currentLanguage = router.locale?.toLowerCase() as unknown as AvailableLanguages ?? 'fr';

    // Check if the language needs to be updated
    const nextLocaleCookie = CookiesService.getCookie('NEXT_LOCALE') as unknown as AvailableLanguages ?? 'fr';
    if (nextLocaleCookie && nextLocaleCookie !== currentLanguage) {
      // If the cookie exists and differs from the current language, use the cookie value
      dispatch(setLanguage(nextLocaleCookie));
    } else if (router.locale !== router.defaultLocale || !nextLocaleCookie) {
      // If no cookie or router locale differs from default, update cookie and state
      const date = new Date();
      const expireMs = 100 * 24 * 60 * 60 * 1000; // 100 days
      date.setTime(date.getTime() + expireMs);
      CookiesService.setCookie('NEXT_LOCALE', currentLanguage, {
        expires: date
      });
      dispatch(setLanguage(currentLanguage));

      // Change axios header for locale
      axiosInstance.defaults.headers.common['Accept-Language'] = currentLanguage;

      // Redefine Yup locale
      YupService.setup(currentLanguage).then(() => console.log('Yup locale changed to', currentLanguage)).catch(console.error);
    }
  }, [router.locale, router.defaultLocale, dispatch]);

  // Render
  // ----------------------------------------------------------------------------
  return <HistoryManagerContext.Provider value={historyManager} data-sentry-element="unknown" data-sentry-component="SenskleAppStructure" data-sentry-source-file="SenskleAppStructure.tsx">
			<ThemeProvider theme={MUITheme} data-sentry-element="ThemeProvider" data-sentry-source-file="SenskleAppStructure.tsx">
				<MotionLazy data-sentry-element="MotionLazy" data-sentry-source-file="SenskleAppStructure.tsx">
					{/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
					<CssBaseline data-sentry-element="CssBaseline" data-sentry-source-file="SenskleAppStructure.tsx" />
					<DefaultSeo {...defaultDataProps} data-sentry-element="DefaultSeo" data-sentry-source-file="SenskleAppStructure.tsx" />
					<ToastConfig data-sentry-element="ToastConfig" data-sentry-source-file="SenskleAppStructure.tsx" />
					<AnimatePresence mode="wait" data-sentry-element="AnimatePresence" data-sentry-source-file="SenskleAppStructure.tsx">
						<m.div key={key} initial={{
            opacity: 0
          }} animate={{
            opacity: 1,
            transition: {
              duration: 0.5
            }
          }} data-sentry-element="unknown" data-sentry-source-file="SenskleAppStructure.tsx">
							{children}
						</m.div>
						<SplashScreen duration={5500} data-sentry-element="SplashScreen" data-sentry-source-file="SenskleAppStructure.tsx" />
					</AnimatePresence>
				</MotionLazy>
			</ThemeProvider>
		</HistoryManagerContext.Provider>;
};
const SplashScreen: React.FC<{
  duration: number;
}> = ({
  duration
}) => {
  const [show, setShow] = React.useState(true);
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, duration);
    return () => clearTimeout(timer);
  }, [duration]);
  return <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-component="SplashScreen" data-sentry-source-file="SenskleAppStructure.tsx">
			{show ? <m.div initial={{
      opacity: 1
    }} animate={{
      opacity: 1
    }} exit={{
      opacity: 0
    }} transition={{
      duration: 0.5
    }} style={{
      position: 'fixed',
      top: 0,
      left: 0,
      height: '100vh',
      width: '100vw',
      backgroundColor: '#000000',
      zIndex: 9998,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
					<SenskleTextSvgAnimated style={{
        width: '100%',
        height: 'fit-content',
        maxWidth: '300px'
      }} />
				</m.div> : null}
		</AnimatePresence>;
};