/*
| Developed by Dirupt
| Filename : BadgePremium.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface BadgePremiumProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const BadgePremium: React.FC<BadgePremiumProps> = React.forwardRef((props, ref) => {
  return <SvgIcon {...props} inheritViewBox ref={ref}>
			<svg viewBox="0 0 64 64">
				<path fillRule="evenodd" clipRule="evenodd" d="M31.9852 15.9778C28.8212 15.9778 25.736 16.9142 23.1141 18.6687C20.4922 20.4232 18.442 22.917 17.2296 25.8346C16.0172 28.7522 15.7018 31.9655 16.3228 35.0605C16.9438 38.1556 18.4617 41.0042 20.6992 43.2416C22.9367 45.4791 25.7755 46.9971 28.8803 47.6181C31.9754 48.239 35.1887 47.9138 38.1063 46.7112C41.0239 45.4988 43.5176 43.4585 45.2721 40.8267C47.0266 38.2048 47.963 35.1098 47.963 31.9556C47.963 27.7172 46.2775 23.6562 43.2811 20.6598C40.2846 17.6633 36.2236 15.9778 31.9852 15.9778ZM39.2891 30.5757L32.1626 37.7021C31.7289 38.126 31.1572 38.3625 30.556 38.3625C29.9547 38.3625 29.3732 38.126 28.9493 37.7021L24.6518 33.4243C24.2279 32.9906 23.9914 32.4091 23.9914 31.8078C23.9914 31.2065 24.2279 30.625 24.6518 30.1913C24.8588 29.9744 25.115 29.8069 25.391 29.6886C25.667 29.5703 25.9627 29.5112 26.2683 29.5112C26.5738 29.5112 26.8696 29.5703 27.1455 29.6886C27.4215 29.8069 27.6778 29.9744 27.8848 30.1913L30.5363 32.8625L36.0659 27.3328C36.4996 26.909 37.0713 26.6724 37.6824 26.6724C38.2837 26.6724 38.8652 26.9188 39.2891 27.3427C39.7129 27.7764 39.9495 28.3481 39.9495 28.9592C39.9495 29.5604 39.7031 30.142 39.2792 30.5658L39.2891 30.5757Z" fill="#7CD896" />
				<path fillRule="evenodd" clipRule="evenodd" d="M57.4748 21.9609C57.1594 21.4878 56.9426 20.9456 56.8341 20.3838L55.0599 11.6408C54.8825 10.7636 54.4389 9.95534 53.8081 9.3245C53.1773 8.69367 52.369 8.25997 51.4918 8.08255L42.9262 6.30833C42.3545 6.19005 41.8124 5.96334 41.3294 5.62821L34.065 0.778685C33.3159 0.266133 32.4288 0 31.5318 0C30.6348 0 29.7477 0.27599 28.9986 0.778685L21.7342 5.62821C21.2512 5.96334 20.6992 6.19005 20.1177 6.30833L11.5718 8.08255C10.6946 8.26983 9.8962 8.70353 9.26536 9.34422C8.63453 9.97505 8.21069 10.7833 8.03327 11.6606L6.25905 20.4035C6.12105 20.9555 5.89435 21.4878 5.57893 21.9609L0.729401 29.452C0.246419 30.2011 0 31.0587 0 31.9458C0 32.8329 0.256276 33.7003 0.729401 34.4395L5.55922 41.9307C5.86478 42.4137 6.08163 42.9459 6.19991 43.5078L7.97413 52.2507C8.14169 53.1477 8.56553 53.9757 9.20622 54.6262C9.84691 55.2768 10.6552 55.7203 11.5521 55.9076L20.098 57.6818C20.6795 57.7902 21.2315 58.0268 21.7145 58.3619L28.9789 63.2115C29.728 63.724 30.6151 63.9901 31.5121 63.9901C32.4091 63.9901 33.2962 63.7142 34.0453 63.2115L41.3097 58.3619C41.7927 58.0268 42.3348 57.8001 42.9065 57.6818L51.472 55.9076C52.3493 55.7203 53.1477 55.2866 53.7785 54.6459C54.4094 54.0151 54.8332 53.2068 55.0106 52.3296L56.7848 43.5866C56.8933 42.9952 57.12 42.4235 57.465 41.9307L62.3342 34.4395C62.8073 33.7003 63.0636 32.8428 63.0636 31.9655C63.0636 31.0882 62.8073 30.2307 62.3342 29.4915L57.465 21.9609H57.4748ZM31.9754 52.4676C27.9144 52.4676 23.9519 51.2651 20.5711 49.0079C17.2001 46.7507 14.5683 43.5472 13.0109 39.7918C11.4536 36.0363 11.0494 31.9162 11.8478 27.9341C12.6364 23.9519 14.5979 20.2951 17.4662 17.4268C20.3345 14.5584 23.9914 12.597 27.9735 11.8084C31.9556 11.0199 36.0856 11.424 39.8312 12.9715C43.5866 14.5289 46.7901 17.1606 49.0473 20.5316C51.3045 23.9027 52.507 27.8749 52.507 31.9359C52.4972 37.3769 50.3287 42.5911 46.4845 46.4352C42.6404 50.2794 37.4262 52.4479 31.9852 52.4577L31.9754 52.4676Z" fill="#7CD896" />
			</svg>
		</SvgIcon>;
});
BadgePremium.displayName = 'BadgePremium';