/*
| Developed by Dirupt
| Filename : DesktopUserButton.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import NextLink from 'next/link';
import { LinkProps } from 'next/dist/client/link';
import useTranslation from 'next-translate/useTranslation';
import { useMe } from '@/services/api/modules/user/hooks/useMe';
import { useActiveLink } from '@/hooks/useActiveLink';
import { UrlsConfig } from '@/config/urls.config';
import { DesktopNavContainer } from '@/components/layouts/app/DefaultLayout/DesktopNav/DesktopNavItem';
import { MediaServices } from '@/services/api/modules/media/media.services';
import { MAvatar, MAvatarProps } from '@/components/common/MAvatar';
import { UsernameWithBadge } from '@/components/common/UsernameWithBadge';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface DesktopUserButtonProps {}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const AvatarIcon = styled(MAvatar)<MAvatarProps>(() => ({
  width: 38,
  height: 38
}));
const UserButtonLink = styled(NextLink)<LinkProps>(({
  theme
}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
  textDecoration: 'none',
  color: theme.palette.text.primary,
  '&:hover': {
    color: theme.palette.primary.main
  }
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DesktopUserButton: React.FC<DesktopUserButtonProps> = () => {
  const {
    t
  } = useTranslation();
  const {
    data: user
  } = useMe();
  const detailsActive = useActiveLink(user ? UrlsConfig.profile.details(user.id) : UrlsConfig.account.index);
  const indexActive = useActiveLink(UrlsConfig.account.index);
  const active = React.useMemo(() => detailsActive || indexActive, [detailsActive, indexActive]);

  // Get sub label
  // ----------------------------------------------------------------------------
  const subLabel = React.useMemo(() => {
    if (!user) return undefined;
    let label = t(`common:userRoles.${user.role}`);
    if (user.isSubscriptionActive) {
      label += ` - ${t('common:subscription.active')}`;
    }
    return label;
  }, [t, user]);

  // Render
  // ----------------------------------------------------------------------------
  return <DesktopNavContainer active={active} data-sentry-element="DesktopNavContainer" data-sentry-component="DesktopUserButton" data-sentry-source-file="DesktopUserButton.tsx">
			<UserButtonLink href={UrlsConfig.account.index} data-sentry-element="UserButtonLink" data-sentry-source-file="DesktopUserButton.tsx">
				{user?.userInformation?.avatarUrl ? <AvatarIcon src={MediaServices.getAvatarFromUser(user, {
        w: 38
      })} /> : <AvatarIcon src={'/assets/images/avatar/senskle-default.jpg'} />}
				<Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} gap={0} data-sentry-element="Box" data-sentry-source-file="DesktopUserButton.tsx">
					<UsernameWithBadge user={user} typographyProps={{
          variant: 'subtitle1'
        }} data-sentry-element="UsernameWithBadge" data-sentry-source-file="DesktopUserButton.tsx" />
					{subLabel ? <Typography color={'primary'} variant={'caption'}>
							{subLabel}
						</Typography> : null}
				</Box>
			</UserButtonLink>
		</DesktopNavContainer>;
};