/*
| Developed by Dirupt
| Filename : user-information.endpoint.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { AxiosRequestConfig } from 'axios'
import { setUser } from '@sentry/nextjs'
import { axiosInstance } from '@/services/api/axios-instance'
import {
	User,
	UserFindOne,
	UserFollowersFollowings,
	UserLastRegistered,
} from '@/services/api/modules/user/user.contract'
import {
	UserChangeLanguageDto,
	UserChangePasswordDto,
	UserCreateDto,
	UserFollowOrUnfollowDto,
	UserIsFollowingDto,
	UserResendVerifyDto,
	UserVerifyDto,
} from '@/services/api/modules/user/user.dto'
import { DiruptUtils } from '@/utils'

/*
|--------------------------------------------------------------------------
| Account URLs
|--------------------------------------------------------------------------
*/
export type GetUserDirectoryQueryString = {
	page: number
	pseudo?: string
}
export const userEndpointUrls = {
	// For authenticated user
	// ----------------------------------------------------------------------------
	getMe: () => '/user',
	getOne: (userId: string) => `/user/${userId}`,
	create: () => '/user/create',
	verify: () => '/user/verify',
	verifyResend: () => '/user/verify/resend',
	// Followers / Following
	// ----------------------------------------------------------------------------
	getFollowers: () => '/user/followers',
	getFollowing: () => '/user/following',
	isFollowing: (userId: string) => `/user/following/${userId}`,
	follow: (userId: string) => `/user/follow/${userId}`,
	unfollow: (userId: string) => `/user/unfollow/${userId}`,
	// For other user
	// ----------------------------------------------------------------------------
	getLastRegistered: () => '/user/last-registered',
	getUserDirectory: (queryString: GetUserDirectoryQueryString) =>
		DiruptUtils.buildURL('/user/directory', queryString),
	// User was viewed by another user
	// ----------------------------------------------------------------------------
	notifyProfileView: (userId: string) => `/user/viewed/${userId}`,
	// Change language and password
	// ----------------------------------------------------------------------------
	changePassword: () => '/user/change-password',
	changeLanguage: () => '/user/change-language',
} as const

export const userSWRKeys = {
	getUserDirectory: () => 'USER_DIRECTORY',
} as const

/*
|--------------------------------------------------------------------------
| Account endpoint
|--------------------------------------------------------------------------
*/
export class UserEndpoint {
	/*
	|--------------------------------------------------------------------------
	| For authenticated user
	|--------------------------------------------------------------------------
	*/

	// [GET] - /user - (AUTH)
	// ----------------------------------------------------------------------------
	public static async getMe(options?: AxiosRequestConfig) {
		const user = await axiosInstance<User>({ ...options, url: userEndpointUrls.getMe(), method: 'GET' })

		setUser({
			id: user.data?.id ?? undefined,
			email: user.data?.email ?? undefined,
			username: user.data?.userInformation?.pseudo ?? undefined,
		})

		return user
	}

	// [GET] - /user/:userId - (AUTH)
	// ----------------------------------------------------------------------------
	public static getOne(userId: string, options?: AxiosRequestConfig) {
		return axiosInstance<UserFindOne>({ ...options, url: userEndpointUrls.getOne(userId), method: 'GET' })
	}

	// [POST] - /user - (GUEST)
	// ----------------------------------------------------------------------------
	public static create(data: UserCreateDto, options?: AxiosRequestConfig) {
		return axiosInstance<{ beta: boolean }>({ ...options, url: userEndpointUrls.create(), method: 'POST', data })
	}

	// [POST] - /user/verify - (AUTH)
	// ----------------------------------------------------------------------------
	public static verify(data: UserVerifyDto, options?: AxiosRequestConfig) {
		return axiosInstance<User>({ ...options, url: userEndpointUrls.verify(), method: 'POST', data })
	}

	// [POST] - /user/verify/resend - (AUTH)
	// ----------------------------------------------------------------------------
	public static resendVerify(data: UserResendVerifyDto, options?: AxiosRequestConfig) {
		return axiosInstance<User>({ ...options, url: userEndpointUrls.verifyResend(), method: 'POST', data })
	}

	/*
	|--------------------------------------------------------------------------
	| Followers / Following
	|--------------------------------------------------------------------------
	*/

	// [GET] - /user/followers - (AUTH)
	// ----------------------------------------------------------------------------
	public static getFollowers(options?: AxiosRequestConfig) {
		return axiosInstance<Array<UserFollowersFollowings>>({
			...options,
			url: userEndpointUrls.getFollowers(),
			method: 'GET',
		})
	}

	// [GET] - /user/following - (AUTH)
	// ----------------------------------------------------------------------------
	public static getFollowing(options?: AxiosRequestConfig) {
		return axiosInstance<Array<UserFollowersFollowings>>({
			...options,
			url: userEndpointUrls.getFollowing(),
			method: 'GET',
		})
	}

	// [GET] - /user/following/:userId - (AUTH)
	// ----------------------------------------------------------------------------
	public static isFollowing(userId: string, options?: AxiosRequestConfig) {
		return axiosInstance<UserIsFollowingDto>({
			...options,
			url: userEndpointUrls.isFollowing(userId),
			method: 'GET',
		})
	}

	// [POST] - /user/follow/:userId - (AUTH)
	// ----------------------------------------------------------------------------
	public static follow(userId: string, options?: AxiosRequestConfig) {
		return axiosInstance<UserFollowOrUnfollowDto>({
			...options,
			url: userEndpointUrls.follow(userId),
			method: 'POST',
		})
	}

	// [POST] - /user/unfollow/:userId - (AUTH)
	// ----------------------------------------------------------------------------
	public static unfollow(userId: string, options?: AxiosRequestConfig) {
		return axiosInstance<UserFollowOrUnfollowDto>({
			...options,
			url: userEndpointUrls.unfollow(userId),
			method: 'POST',
		})
	}

	/*
	|--------------------------------------------------------------------------
	| For other user
	|--------------------------------------------------------------------------
	*/

	// [GET] - /user/last-registered - (AUTH)
	// ----------------------------------------------------------------------------
	public static getLastRegistered(options?: AxiosRequestConfig) {
		return axiosInstance<Array<UserLastRegistered>>({
			...options,
			url: userEndpointUrls.getLastRegistered(),
			method: 'GET',
		})
	}

	// [GET] - /user/directory - (AUTH)
	// ----------------------------------------------------------------------------
	public static getUserDirectory(queryString: GetUserDirectoryQueryString, options?: AxiosRequestConfig) {
		return axiosInstance<Array<User>>({
			...options,
			url: userEndpointUrls.getUserDirectory(queryString),
			method: 'GET',
		})
	}

	/*
	|--------------------------------------------------------------------------
	| User was viewed by another user
	|--------------------------------------------------------------------------
	*/

	// [POST] - /user/viewed/:userId - (AUTH)
	// ----------------------------------------------------------------------------
	public static notifyProfileView(userId: string, options?: AxiosRequestConfig) {
		return axiosInstance<User>({
			...options,
			url: userEndpointUrls.notifyProfileView(userId),
			method: 'POST',
		})
	}

	/*
	|--------------------------------------------------------------------------
	| Change language and password
	|--------------------------------------------------------------------------
	*/

	// [PATCH] - /user/change-password - (AUTH)
	// ----------------------------------------------------------------------------
	public static changePassword(data: UserChangePasswordDto, options?: AxiosRequestConfig) {
		return axiosInstance<User>({ ...options, url: userEndpointUrls.changePassword(), method: 'PATCH', data })
	}

	// [PATCH] - /user/change-language - (AUTH)
	// ----------------------------------------------------------------------------
	public static changeLanguage(data: UserChangeLanguageDto, options?: AxiosRequestConfig) {
		return axiosInstance<{ message: string; preferredLnaguage: string }>({
			...options,
			url: userEndpointUrls.changeLanguage(),
			method: 'PATCH',
			data,
		})
	}
}
