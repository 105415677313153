/*
| Developed by Dirupt
| Filename : LangSwitch.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import { Language as LanguageIcon } from '@mui/icons-material';
import useTranslation from 'next-translate/useTranslation';
import setLanguage from 'next-translate/setLanguage';
import { MButton } from '@/components/common/MButton';
import { useAppDispatch, useAppSelector } from '@/stores/hooks';
import { selectLanguage, setLanguage as setStoreLanguage } from '@/stores/common/commonSlice';
import { UserEndpoint } from '@/services/api/modules/user/user.endpoint';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface LangSwitchProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const LangSwitch: React.FC<LangSwitchProps> = () => {
  const {
    t
  } = useTranslation();
  const dispatch = useAppDispatch();
  const lang = useAppSelector(selectLanguage);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const changeLanguage = async (newLang: string) => {
    try {
      await setLanguage(newLang);
      dispatch(setStoreLanguage(newLang));

      // API call to save the language change
      await UserEndpoint.changeLanguage({
        language: newLang
      });
    } catch (error) {
      console.error(error);
    } finally {
      handleClose();
    }
  };
  return <div data-sentry-component="LangSwitch" data-sentry-source-file="LangSwitch.tsx">
			<MButton onClick={handleClick} startIcon={<LanguageIcon />} variant="outlined" color="primary" size="small" data-sentry-element="MButton" data-sentry-source-file="LangSwitch.tsx">
				{t(`common:language.lang.${lang}`)}
			</MButton>
			<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} data-sentry-element="Menu" data-sentry-source-file="LangSwitch.tsx">
				<MenuItem onClick={() => changeLanguage('fr')} selected={lang === 'fr'} data-sentry-element="MenuItem" data-sentry-source-file="LangSwitch.tsx">
					{t('common:language.lang.fr')}
				</MenuItem>
				<MenuItem onClick={() => changeLanguage('en')} selected={lang === 'en'} data-sentry-element="MenuItem" data-sentry-source-file="LangSwitch.tsx">
					{t('common:language.lang.en')}
				</MenuItem>
			</Menu>
		</div>;
};